.fc-section{
    padding: 56px 2em;
}

.fc-content>h2{
    padding-bottom: 24px;
}

.fc-content>p{
    padding-bottom: 44px;
    font-family: jost200 , sans-serif;
}

.fc-content>p,.fc-content>h2{
    text-align: center;
}

@media only screen and (min-width: 10px) {
    .fc-content{
        width: 98%;
    }

    .fc-cards-div{
        width: 100%;
        flex-wrap: wrap;
        justify-content: center;

    }
}

@media only screen and (min-width: 500px) {
    .fc-cards-div .cardSection{
        width: 25em;
        height: auto;
    }
}

@media only screen and (min-width: 768px) {
    .fc-content{
        width: 90%;
    }

    .fc-cards-div{
        width: 90%;
        flex-wrap: wrap;
    }

    .fc-cards-div .cardSection{
        width: 28em;
        height: auto;
    }
}

@media only screen and (min-width: 1200px) {
    .fc-content{
        width: 60%;
    }

    
    .fc-cards-div{
        width: 100%;
        flex-wrap: nowrap;
    }

}

@media only screen and (min-width:1400px){
    .fc-cards-div .cardSection{
        width: 30em;
        height: auto;
    }
}