.bt-form{
    padding: 15px;
    max-width: 600px;
    margin: auto;
}
.bt-form-heading{
    font-size: 1.8em;
    font-weight: 500;
    margin:15px auto;
}
.bt-field-container{
    display: flex;
    justify-content: center;
    gap: 15px;
    flex-direction: column;
}
.bt-form-input-wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    column-gap: 15px;
}
.bt-form-input-wrapper>label{
    text-align: left;
    font-weight: 400;
    font-size: 1.1em;
    margin-bottom: .3em;
}
.bt-input{
    width: 100%;
    height: 40px;
    border:1px solid grey;
    padding: 8px 10px;
}
.bt-input-error{
    text-align: left;
    color: red;
    font-size: .8em;
}

.bt-form-actions{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 15px;
    margin:10px auto
}