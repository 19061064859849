.csm-out{
    background:var(--bg-light);
    color: #4B4B4D;

}

.csm-out>h2{
    font-family: heading, sans-serif;
    font-size: 3em;
    font-weight: 450;
}

.csm-out>span{
    font-family: heading, sans-serif;
    font-size: 1.3em;
}

.csm-out p{
    font-weight: 400;
}

.link-btn{
    background: #66706F;
    color: var(--bg-light);
    border: none;
    border-radius: 0.2em;
    padding: 0.5em 5em;
    width: fit-content;
    font-size: 0.9em ;
    margin-top: 2em;
}

.center-divider{
    border-left: 1px solid #66706F;
    height: 6em;
}

@media only screen and (min-width: 10px) {
    .csm-out{
        /* padding: 1em 2em; */
        width: 100%;
    }

    .csm-out>h2{
        font-size: 1.5em;
    }

    .center-divider{
        height: 3em;
    }

    .csm-out>span{
        font-size: 0.9em;
    }

    .csm-out p{
        font-weight: 400;
        font-size: 0.8em;
    }

    .link-btn{
        padding: 0.5em 3em;
    }
}

@media only screen and (min-width: 350px) {
    .csm-out{
        padding: 1em 2em;
        /* width: 100%; */
    }

    .csm-out>h2{
        font-size: 2em;
    }

    .center-divider{
        height: 4em;
    }

    .csm-out>span{
        font-size: 0.9em;
    }

    .link-btn{
        padding: 0.5em 3em;
    }
}

@media only screen and (min-width: 450px) {
    .csm-out{
        padding: 2em 3em;
    }

    .csm-out>h2{
        font-size: 2em;
    }

    .center-divider{
        height: 4em;
    }

    .csm-out>span{
        font-size: 0.9em;
    }

    .csm-out p{
        font-weight: 400;
        font-size: 1em;
    }
}

@media only screen and (min-width: 580px) {
    .csm-out{
        padding: 3em 5em;
    }

    .csm-out>h2{
        font-size: 3em;
    }

    .center-divider{
        height: 6em;
    }

    .csm-out>span{
        font-size: 1.3em;
    }
}

@media only screen and (min-width: 768px) {
    .csm-out{
        padding: 5em 8em;
    }
}