/* ================================Cart Total Css==================================== */


.cartSection{
    padding: 5em 2em;
    background: rgb(244, 244, 244);
    
}

.cartProductList,.cartProductTotal{
    
    background: white;
    padding: 2em 2em;
    height: fit-content;
    border-radius: 0.5em;
    -webkit-border-radius: 0.5em;
    -moz-border-radius: 0.5em;
    -ms-border-radius: 0.5em;
    -o-border-radius: 0.5em;
}

.cartProductTotal{
    min-width: 25em;
}

.cartProductTotal>hr{
    height: 0em;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    opacity: 0.5;
}

.cartProductTotal h3{
    color: gray;
    font-weight: 500;
}

.cartProductTotal p{
    color: gray;
}

.cartProductTotal h2{
    font-weight: 500;
}

.cartProductTotal button{
    width: 100%;
}

@media only screen and (min-width: 10px){
    .cartSection>div{
        width: 100%;
        flex-direction: column;
    }

    .cartSection{
        padding: 5em 1em;
    }
}

@media only screen and (min-width: 768px){
    .cartSection>div{
        width: 80%;
    }

    .cartSection{
        padding: 5em 2em;
    }
}

@media only screen and (min-width: 1200px){
    .cartSection>div{
        width: 90%;
        flex-direction: row;

    }
}

@media only screen and (min-width: 1400px){
    .cartSection>div{
        width: 80%;
    }
}


/* ================================Cart Product Css==================================== */

hr{
    width: 100%;
    opacity: 0.2;
    margin-bottom: 1.5em;
}

.cartProduct{
    width: 100%;
    padding: 0;
}

.cartProductImg{
    overflow: hidden;
    width: 15%;
    min-width: 8em;
    border-radius: 1em;
    -webkit-border-radius: 1em;
    -moz-border-radius: 1em;
    -ms-border-radius: 1em;
    -o-border-radius: 1em;
}

.cartProductName h3{
    font-weight: 500;
    text-align: left;
    letter-spacing: 0.05em;
}

.cartProductPrice{
    gap: 0.5em;
}

.cartProductPrice p{
    color: grey;
}

.cartProductPrice .cartProductAvailability{
    color: rgb(0, 192, 0);
    /* font-size: 1.2em; */
    font-weight: 600;
}

.cartProductTotalPrice h2{
    font-weight: 500;
    min-width: max-content;
}

.addOrRemoveQuantity{
    background: transparent;
    border: 1px solid rgb(231, 231, 231);
    color: grey;
    overflow: hidden;
    border-radius: 0.8em;
    -webkit-border-radius: 0.8em;
    -moz-border-radius: 0.8em;
    -ms-border-radius: 0.8em;
    -o-border-radius: 0.8em;
}
.addOrRemoveQuantity button{
    background: none;
    padding: 0.5em 1em;
    border: none;
    cursor: pointer;
}

.cartProductList>div{
    gap: 1.5em;
}

.cartProductList>h2{
    text-align: left;
    margin-top: -0.5em;
    margin-bottom: 0.5em;
    font-weight: 500;
    letter-spacing: 0.02em;
}

.cartProductTxt{
    position: relative;
}

.cartProductTotalPriceMob{
    position: absolute;
    right: 0;
    bottom: 0;
}

@media only screen and (min-width: 10px){
    .cartProductBottomDiv{
        flex-direction: column;
        gap: 2em;
    }

    .cartProductImg{
        min-width: 4em;
    }

    .cartProductTotalPriceMob>h2,
    .cartProductFavIcon{
        font-size: 0.9em;
    }

    .cartProductDelete{
        position: absolute;
        top: 0;
        right: 0;
        opacity: 0.5;
    }

    .cartProductName>h3{
        font-size: 0.8em;
    }

    .cartProductTotal{
        min-width: auto;
    }

    .cartProductAvailability,
    .cartProductTotalPriceMob>p{
        font-size: 0.8em;
        font-weight: 400;
        display: none;
    }

    .cartProductList{
        padding: 2em 1em;
    }
}

@media only screen and (min-width: 360px){
    .cartProductAvailability,
    .cartProductTotalPriceMob>p{
        font-size: 0.8em;
        font-weight: 400;
        display: flex;
    }
}


@media only screen and (min-width: 460px){
    .cartProductName>h3{
        font-size: 1em;
    }

    .cartProductTotal{
        min-width: 25em;
    }

    .cartProductAvailability{
        font-size: 0.9em;
        font-weight: 400;
    }

    .cartProductList{
        padding: 2em;
    }
}


@media only screen and (min-width: 600px){
    .cartProductBottomDiv{
        flex-direction: row;
        gap: 0;
    }

    .cartProductImg{
        min-width: 8em;
    }

    .cartProductSaveOrDelDiv{
        position: absolute;
        right: 0;
        bottom: 0;
    }

    .cartProductSaveOrDelDiv a{
        color: rgb(164, 164, 164);
    }
}