.authenticationSection{
    justify-content: flex-start !important;
    /* align-items: flex-start !important; */
}

.custDetailFormSection{
    padding: 3em 1em;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: rgba(245, 245, 245, 1); */
    overflow-y: auto;
}

.custDetailForm{
    width: 80%;
    /* backdrop-filter: blur(8px); */
    /* background: rgba(255, 255, 255, 0.5); */
    /* background: rgba(245, 245, 245, 1); */

}

.container-component{
    border: 1px solid rgba(0,0,0,0.1);
    padding: 2em;
    border-radius: 0.5em;
    background: white;
    /* box-shadow: 0 0 01em .01em rgba(0, 0, 0, 0.1); */
}

.row-container-component{
    display: grid;
    grid-template-columns: 1fr 1fr;
}



@media only screen and (min-width:100px){
    .row-container-component{
        display: grid;
        grid-template-columns: 1fr;
        gap: 1em;
    }

    .custDetailForm{
        width: 100%;
    }

    .custDetail-btn button{
        padding: 1em 4em;
        font-size: 1.2em;
    }
}


@media only screen and (min-width:500px){
    .row-container-component{
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    
    .custDetailForm{
        width: 80%;
    }
}