.imageSection{
    width: 100%;
    height: 100%;
    /* text-align: left; */
    
}

.imageSection>img{
    max-width: 100%;
    max-height: 100%;
}