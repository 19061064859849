.shortBannerSection{
    padding: 56px 0em ;
    background: var(--header-dark);

}

.shortBannerTextDiv{
    color: var(--txt-light);
}

.shortBannerTextDiv>.inputDiv{
    background: var(--header-dark);
    overflow: hidden;
    border-bottom: 1px solid var(--txt-light);
    border-radius: 0em;
    -webkit-border-radius: 0em;
    -moz-border-radius: 0em;
    -ms-border-radius: 0em;
    -o-border-radius: 0em;
}

.shortBannerTextDiv>.inputDiv>input[type="text"]{
    outline: none;
    border: none;
    min-width: 20em;
    font-size: 1.3em;
    padding: 1em 0.5em;
    letter-spacing: 0.1em;
    color: var(--txt-light);
    background: var(--header-dark);
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}

.shortBannerTextDiv>.inputDiv>input[type="text"]::placeholder{
    color: var(--txt-light);
    opacity: .9;
}

.shortBannerTextDiv>.inputDiv>input[type="text"]:hover,
.shortBannerTextDiv>.inputDiv>input[type="text"]:focus{
    outline: none;
    border: none;
    /* padding: 1em 1.5em; */
    color: var(--txt-light);
    /* background: rgb(253, 230, 217); */
}

.shortBannerTextDiv>.inputDiv>input[type="text"]:hover .inputDiv{
    background: rgb(253, 230, 217);
}

@media only screen and (min-width: 10px) {
    .shortBannerSection{
        padding: 5em 1em;
    }

    .shortBannerTextDiv{
        flex-direction: column;
    }

    .inputDiv{
        width: 100%;
        overflow: hidden;
    }

    .inputDiv input{
        width: 100%;
        min-width: auto !important;
        min-width: 20em;
    }
}

@media only screen and (min-width: 900px) {
    .shortBannerTextDiv{
        flex-direction: row;
    }

    .inputDiv{
        width: auto;
    }
}

@media only screen and (min-width: 1200px) {
    .shortBannerSection{
        padding: 5em;
    }

    .inputDiv input{
        width: 100%;
        min-width: 20em;
    }
}