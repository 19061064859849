.bt-alert-background{
    position: fixed;
    inset: 0;
    background: rgba(0, 0, 0, 0.2);
    z-index: 999;
}

.bt-confirm{
    position: fixed;
    width: 100%;
    max-width: 380px;
    min-width: 300px;
    margin:40px;
    min-height: 100px;
    z-index: 1000;
    left: 50%;
    top: 50%;
    border-radius: 10px;
    padding: 2em 3em;
    translate: -50% -50%;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1em;
    background:white;
    box-shadow: 0 0 1em 0.2em rgba(0, 0, 0, 0.1);
    /* animation-name: example; */
    /* animation-duration: .5s; */
}

.bt-confirm-div>h2{
    font-size: 2em;
    color: grey;
    font-weight: 500;
    text-align: center;
}

.bt-confirm-div>p{
    font-size: 1.1em;
}

.bt-confirm.type-error{
    background: rgb(231, 49, 49);
}

.bt-confirm.type-success{
    background: rgb(10, 167, 96);
}

.bt-buttons-container{
    display: flex;
    gap: 1em;
    margin-top: 1em;
    align-items: center;
    justify-content: end;
}

.bt-buttons-container>button{
    padding: 1em 2.5em;
    border-radius: 0.2em;
    font-size: 1em;
    border: none;
    outline: none;
    cursor: pointer;
    background: white;
    color: black;
}

.bt-buttons-container>button.accept{
    background: green;
    color: white;
}

.bt-buttons-container>button.cancel{
    background: rgb(230, 230, 230);
    color: black;
}

/* 
@keyframes example {
    from {top: 10%;}
    to {top: 13%;}
  } */