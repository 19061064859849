.appointBannerSection{
    width: 100%;
    /* height: 98vh; */
    overflow: hidden;
    position: relative;
}

.appointBannerImgDiv{
    position: absolute;
    inset: 0;
    z-index: -1;
    background: url('../../../assets/images/background-appointment@2x.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.appointBannerImgDiv img{
    width: 100%;
    height: 100%;
}


.appointBannerContent{
    padding: 124px 2em;
    color: white;
}

.appointBannerTitleDiv>h2{
    font-weight: 300;
    font-size: 4em;
    letter-spacing: 0.11em;
    padding-top: 44px;
}

.appointBannerMenuDiv>ul a{
    font-size: 2em;
}

.appointBannerMenuDiv>ul p{
    font-size: 1.5em;
    padding: 0 1em;
}

.appointBannerMenu{
    justify-content: center;
    align-items: center;
    padding-top: 10px;
}

.appointBannerSubtitle>p{
    font-size: 1.5em;
    padding: 44px 0em;
}


@media only screen and (min-width: 10px){
    
    .appointBannerTitleDiv>h2{
        font-size: 2.5em;
    }

    .appointBannerMenuDiv>ul p{
        display: none;
    }

    .appointBannerMenuDiv>ul a{
        font-size: 1.2em;
    }

    .appointBannerSubtitle>p{
        font-size: 1em;
    }

    .appointBannerMenuDiv>ul{
        flex-direction: column;
    }
}


@media only screen and (min-width: 450px){
    .appointBannerMenuDiv>ul{
        flex-direction: row;
    }

    .appointBannerMenuDiv>ul p{
        font-size: 1.5em;
        padding: 0 0.5em;
        display: flex;
    }
}

@media only screen and (min-width: 768px){
    .appointBannerMenuDiv>ul p{
        font-size: 2em;
        padding: 0 1em;
    }

    .appointBannerMenuDiv>ul a{
        font-size: 2em;
    }

    .appointBannerSubtitle>p{
        font-size: 1.5em;
        padding: 44px 0em;
    }

    .appointBannerTitleDiv>h2{
        font-weight: 300;
        font-size: 4em;
        letter-spacing: 0.11em;
        padding-top: 44px;
    }

    .appointBannerImgDiv>img{
        width: 100%;
        height: 100%;
    }
}