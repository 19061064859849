.failedSection{
    padding: 8em 3em;
}

.failedIconDiv{
    width: 8em;
    height: 8em;
    background: rgb(255, 54, 54);
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.failedSection>div>p{
    color: grey;
}

.failedSection>div>h3{
    font-size: 2em;
    font-weight: 450;
}

.failedBorder{
    width: 100%;
    border-bottom: 1px dashed var(--header-dark);
    margin: 2em 0;
}