.btui-paper{
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    min-height: 200px;
}
.btui-paper.content-center{
    display: flex;
    justify-content: center;
    align-items: center;
}