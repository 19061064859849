.privacy-policy-section{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1em 0;
    flex-direction: column;
    gap: 0.5em;
    position: relative;
    
}

.privacy-policy-section h1{
    margin-top: 0.5em;
}

.privacy-policy-content{
    width: 80%;
    /* height: 70vh; */
    text-align: justify;
    display: flex;
    flex-direction: column;
    gap: 1.5em;
    overflow-y: auto;
    padding: 0em 2em;
}

.privacy-policy-acceptance-container{
    width: 100%;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    gap: 1.5em;
    padding: 1.3em 1em;
    background: rgba(255, 255, 255, 0.5);
}

.privacy-policy-acceptance-checkbox{
    display: flex;
    gap: 0.5em;
    /* padding: 0 2em; */
    align-items: center;
    justify-content: center;
}

.privacy-policy-content ul{
    margin-left: 2em;
}