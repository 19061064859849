.successSection{
    padding: 8em 3em;
}

.successIconDiv{
    width: 8em;
    height: 8em;
    background: rgb(11, 176, 11);
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.successSection>div>p{
    color: grey;
}

.successSection>div>h3{
    font-size: 2em;
    font-weight: 450;
}

.successBorder{
    width: 100%;
    border-bottom: 1px dashed var(--header-dark);
    margin: 2em 0;
}