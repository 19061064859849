.bt-btn{
    padding: 0.6em 2em;
    outline: none;
    cursor: pointer;
    font-size: 1.1em;
    font-weight: 400;
    border-radius: 0;
    border:none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    /*box-shadow: 0 0 0.5em 0.1em rgba(0,0,0,0.2);
    */transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.bt-dark{
    background: black;
    color: white;
}