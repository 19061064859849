.policies-container-section{
    width: 100%;
    height: 100%;
    padding: 0.5em 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5em;
    position: relative;
    
}

.policies-container-section h1{
    margin-top: 0.5em;
}

.policies-container-content{
    width: 80%;
    text-align: justify;
    display: flex;
    flex-direction: column;
    gap: 1.5em;
    overflow-y: auto;
    padding: 0 2em;
}

.policies-container-acceptance-container{
    width: 100%;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    gap: 1.5em;
    padding: 1.3em 1em;
    background: rgba(255, 255, 255, 0.5);
}

.policies-container-acceptance-checkbox{
    display: flex;
    gap: 0.5em;
    align-items: center;
    justify-content: center;
}
