.followUsSection{
    padding: 56px 5em;
    background: var(--bg-light);
}

.followUsSection>div h2{
    padding-bottom: 30px;
}

@media only screen and (min-width: 10px) {
    .followUsCardDiv{
        flex-direction: column;
        width: 98%;
        justify-content: center;
        align-items: center;
    }

    .followUsSection{
        padding: 5em 1em;
    }

    .followUsCardDiv .cardSection{
        max-width: 100%;
    }
}

@media only screen and (min-width: 500px) {
    
}

@media only screen and (min-width: 768px) {
    .followUsCardDiv{
        flex-direction: row;
        width: 100%;
        flex-wrap: wrap;
        
    }

    .followUsSection{
        padding: 5em 2em;
    }

    .followUsCardDiv .cardSection{
        max-width: 40%;
    }
}

@media only screen and (min-width: 1200px) {
    .followUsSection{
        padding: 56px 2em;
    }

    .followUsCardDiv{
        flex-wrap: nowrap;
    }
}


@media only screen and (min-width: 1400px) {
    .followUsCardDiv{
        width: 80%;
    }
    

    /* .followUsSection{
        padding: 5em;
    } */
}