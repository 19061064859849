.myProfileSection{
    padding: 5em 1em;
    background: rgb(244, 244, 244);
}

.myProfileOrdersSection,
.myProfileInfo{
    padding: 1em;
    background: white;
    height: fit-content;
    border-radius: 0.5em;
    -webkit-border-radius: 0.5em;
    -moz-border-radius: 0.5em;
    -ms-border-radius: 0.5em;
    -o-border-radius: 0.5em;
}

.myProfileInfo h2,
.myProfileOrdersSection>h2{
    font-weight: 400;
    text-align: left;
    width: 100%;
    font-size: 1.2em;
    color: gray;
}

.myProfileInfoImg{
    border: 1px solid var(--header-dark);
    width: 10em;
    height: 10em;
    overflow: hidden;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.myProfileInfoTxt{
    width: 100%;
    gap: 0.5em;
}

.myProfileInfoTxt>h3{
    font-size: 1.8em;
    font-weight: 450;
}

.myProfileInfoTxtAddress{
    text-align: left;
}

.myProfileInfoTxtAddress h3{
    font-weight: 500;

}

.myProfileInfoTxtAddress p{
    color: grey;
}

/* ==============================Order Window css======================================== */

.myProfileOrdersSection{
    /* flex-wrap: wrap; */
    height: fit-content;

}

.myprofileOrderDiv{
    width: 100%;
    display: inline-grid;
    /* grid-template-columns: auto auto auto; */
    grid-template-areas: "card card" "card card";
}

.orderCardSection{
    cursor: pointer;
    min-width: 100%;
    padding: 0.5em;
    background: white;
    max-width: 19em;
    border-radius: 0.5em;
    -webkit-border-radius: 0.5em;
    -moz-border-radius: 0.5em;
    -ms-border-radius: 0.5em;
    -o-border-radius: 0.5em;
}



.orderCardImg{
    width: 100%;
    position: relative;
}

.orderCardImgAbs{
    background: rgba(240, 240, 240, 1);
    position: absolute;
    inset: 0;
    overflow: hidden;
    z-index: 1;
}

.orderCardImgAbs>img{
    grid-area: card;
    max-width: 100%;
    height: 100%;
    border-radius: 0.5em;
    -webkit-border-radius: 0.5em;
    -moz-border-radius: 0.5em;
    -ms-border-radius: 0.5em;
    -o-border-radius: 0.5em;
}

.orderCardTxt{
    text-align: left;
    padding: 0.8em;
}

.orderCardTxt>h2{
    /* font-size: 1.2em; */
    font-weight: 450;
}

.orderCardPriceAbs,
.orderCardStatusAbs{
    position: absolute;
    top: 2em;
    left: 0;
    padding: 0.5em 1em;
    z-index: 99;
    background: rgba(255, 255, 255, 0.9);
}

.orderCardStatusAbs{
    background: rgba(255, 255, 255, 0.9);
    top: 6em;
}


@media only screen and (min-width: 10px){
    .myProfileSection>div {
        width: 100%;
    }

    .myProfileSection>div>div {
        gap: 1em;
        flex-direction: column-reverse;
    }

    .myProfileOrdersSection,
    .myProfileInfo{
        width: 100%;
    }

    .myProfileOrdersSection>div{
        flex-wrap: wrap;
    }

    .orderCardTxt>h2{
        font-size: 1em;
    }

    .orderCardTxt>p{
        font-size: 0.9em;
    }

    .myprofileOrderDiv{
        grid-template-areas: 
        "card"
        "card"
        ;
    }
}

@media only screen and (min-width: 700px){
    .myProfileOrdersSection>div{
        flex-wrap: nowrap;
    }

    .orderCardTxt>h2{
        font-size: 1.2em;
    }

    .orderCardTxt>p{
        font-size: 1em;
    }

    .myprofileOrderDiv{
        grid-template-areas: "card card" "card card";
    }
}

@media only screen and (min-width: 900px){
        
    .myProfileSection>div>div {
        gap: 1em;
        flex-direction: row;
    }

    .myProfileOrdersSection{
        width: 70%;

    }
    .myProfileInfo{
        width: 30%;
    }
}


@media only screen and (min-width: 1200px){
    .myProfileSection>div {
        width: 80%;
    }
    .myProfileSection>div>div {
        gap: 2em;
    }
}