

.bridalProductHeaderBottom ul{
    list-style: none;
}

.bridalProductFilterTxt p, .bridalProductSortByTxt p{
    font-size: 1.1em;
    font-weight: 500;
    letter-spacing: 0.1em;
}

.bridalProductFilterTxt{
    position: relative;
}

.bridalProductDropMenu{
    position: absolute;
    background: var(--header-dark);
    /* width: 100%; */
    min-width: 10em;
    display: none;
}

.bridalProductDropMenu li{
    width: 100%;
    /* text-align: left; */
    height: 100%;
}

.bridalProductDropMenu li a{
    /* padding: 0.5em; */
    width: 100%;
    height: 100%;
    padding: 1em !important;
    font-size: 1.2em;
    color: white;
}

.bridalProductDropMenu a:hover{
    background: var(--txt-light);
    
}

.bridalProductDropMenu a:hover {
    color: var(--header-dark);
}

.bridalProductContent{
    padding: 2em 0em;
    flex-wrap: wrap;
}

.productImgDiv{
    position: relative;
    padding-top: 125%;
    
}

.productAbs{
    position: absolute;
    inset: 0;
    overflow: hidden;

}

.productAbs img{
    /* width: 100%; */
    max-width: 100%;
    max-height: 100%;
    /* object-fit: fill; */
}

.bridalProductHeader{
    padding-top: 3em;
}

.bridalProductHeading>p{
    font-size: 1.3em;
    letter-spacing: 0.1em;
    font-weight: 400;
}

.bridalProductHeading>h2{
    font-size: 2.5em;
    font-weight: 400;
    margin: 0.5em 0em;
}



@media only screen and (min-width: 10px) {
    .bridalProductContent>div{
        padding: 0em 2em;
        flex-wrap: wrap;
    }

    .productBtnDiv{
        padding: 5em 0em;
    }

    .bridalProductHeader, .bridalProductContent, .productBtnDiv{
        width: 100%;
        padding: 0 0em;
    }
    
    .bridalProductContent>div{
        gap: 1.5em;
    }

    .productBtnDiv{
        padding: 5em 0em !important;
    }

    .bridalProductHeader{
        padding: 0 2em;
    }

    .bridalProductHeader{
        padding-top: 3em;
    }
}

@media only screen and (min-width: 768px) {
    .bridalProductContent>div{
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .productBtnDiv{
        grid-template-columns: 1fr !important;
    }
}


@media only screen and (min-width: 1000px) {
    .bridalProductContent>div{
        padding: 0em 2em;
        /* flex-wrap: nowrap; */
    }

    .bridalProductContent>div{
        gap: 1.5em;
    }

    .bridalProductHeader{
        padding: 0 2em;
    }

    .bridalProductContent>div{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media only screen and (min-width: 1200px) {
    .bridalProductContent>div{
        padding: 0em 0em;
        /* flex-wrap: nowrap; */
    }

    .productBtnDiv{
        padding: 5em 0em !important;
    }

    .bridalProductHeader, .bridalProductContent, .productBtnDiv{
        width: 80%;
        padding: 0 0em;

    }

    .bridalProductContent>div{
        gap: 3em;
    }

    .bridalProductHeader{
        padding: 0 0em;
    }
}

@media only screen and (min-width: 1200px) {
    .bridalProductContent>div{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}