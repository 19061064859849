.myOrderDetailSection{
    padding: 5em 1em;
    background: rgb(244, 244, 244);

}

.myOrderDetailSection h2{
    font-weight: 450;
    font-size: 1.2em;
    text-align: left;
}

.myOrderDetailSection h3{
    font-weight: 400;
    font-size: 1em;
    color: rgb(68, 68, 68);
}

.myOrderDetailSection p{
    color: rgb(65, 65, 65);
}

.myOrderDetailSection>div{
    width: 90%;
    max-width: 1500px;
}

.myOrderDetailSection hr{
    opacity: 0.08 !important;
    margin-bottom: 0em;
}

.orderDetailCardSection{
    margin-top: -1em;
}

.orderDetailImgContainer{
    width: 100%;
    
    
}

.orderDetailCardSection>div{
    text-align: center !important;
    display: flex;
    align-items: center;
    min-height: 5em;
}


.orderDetailCardImg{
    position: relative;
    
}

.orderDetailCardImg>h2{
    font-size: 1em;
    font-weight: 450;
}

.orderDetailCardImgAbs{
    width: 3.45em;
    height: 5em;
    justify-content: center;
    align-items: center;
}

.orderDetailCardImgAbs>img{
    max-width: 100%;
    max-height: 100%;
}

.orderDetailSummary>div{
    text-align: left;
}


.customerDetailCardSection h3{
    font-weight: 400;
    font-size: 1em;
}

.orderDetailImgContainer>div>div{
    background: white;
    padding: 2em 2em;
    border-radius: 0.5em;
    -webkit-border-radius: 0.5em;
    -moz-border-radius: 0.5em;
    -ms-border-radius: 0.5em;
    -o-border-radius: 0.5em;
}

.orderDetailSummarySection>div{
    gap: 1em;
}

/* =======================Right Side Panel Css============================================== */

.orderDetailTxtDiv{
    width: 50%;
}

.orderDetailTxtDiv>div{
    background: white;
    padding: 2em 2em;
    border-radius: 0.5em;
    -webkit-border-radius: 0.5em;
    -moz-border-radius: 0.5em;
    -ms-border-radius: 0.5em;
    -o-border-radius: 0.5em;
}

.orderDetailTrackOrder button{
    font-size: 0.9em;
    padding: 0.5em 1em;
}

.orderedSummaryDetailDiv{
    text-align: left;
}

.orderSummaryStatus{
    width: fit-content;
    padding: 0.5em 1.5em;
    /* background: rgb(225, 255, 225); */
    text-align: center;
    border-radius: 0.5em;
    -webkit-border-radius: 0.5em;
    -moz-border-radius: 0.5em;
    -ms-border-radius: 0.5em;
    -o-border-radius: 0.5em;
}

/* .orderSummaryStatus>p{
    color: green !important;
} */

/* =========================Responsive Css======================================= */


@media only screen and (min-width: 10px){
    .myOrderDetailSection>div{
        flex-direction: column;
    }

    .orderDetailTxtDiv{
        width: 100%;
    }

    .myOrderDetailSection>div{
        width: 100%;
    }

    /* .orderDetailSummary>div, */
    .orderDetailCardSection>div{
        width: fit-content;
    }

    .customerDetailCardSection,
    .orderSummaryCardSection,
    .deliveryAddressCardSection,
    .orderDetailTrackOrder>div,
    .orderedSummaryDetailDiv{
        flex-direction: column;
        gap: 1em;
        text-align: left;
        align-items: flex-start;
    }

    .customerDetailCardSection>div{
        text-align: left;
    }
}

@media only screen and (min-width: 380px){
    .customerDetailCardSection,
    .orderSummaryCardSection,
    .deliveryAddressCardSection,
    .orderDetailTrackOrder>div,
    .orderedSummaryDetailDiv{
        flex-direction: row;
        align-items: center;

    }

    .customerDetailCardSection>div{
        text-align: right;
    }
}

@media only screen and (min-width: 768px){
    .myOrderDetailSection>div{
        width: 90%;
    }
    
    /* .orderDetailSummary>div, */
    .orderDetailCardSection>div{
        /* width: 50%; */
    }

    .orderDetailCardImg,
    .orderDetailSummaryImg{
        width: 100% !important;
    }
}

@media only screen and (min-width: 1200px){
    .myOrderDetailSection>div{
        flex-direction: row;
    }

    .orderDetailTxtDiv{
        width: 50%;
    }
}