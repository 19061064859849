.bt-toast{
    
    position: fixed;
    max-width: 400px;
    min-width: 300px;
    margin:40px;
    min-height: 100px;
    z-index: 1000;
    left: 50%;
    border-radius: 10px;
    padding: 15px;
    translate: -50%;
    align-items: center;
    display: flex;
    justify-content: center;
}
.bt-toast.type-error{
    background: rgb(231, 49, 49);
}


.bt-toast.type-success{
    background: rgb(10, 167, 96);
}