.cardSection{
    max-width: 100%;
}

.cardImgDiv{
    position: relative;
    width: 100%;
    /* padding-top: 99.99%; */
    /* max-width: 23em; */
}

.cardAbsDiv{
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
}


@media only screen and (min-width: 10px) {
    .cardImgDiv img{
        height: auto;
        width: 100% !important;
    }

    .cardTextDiv{
        padding: 1em 0.5em;
    }

    .cardTextDiv>p{
        font-size: 1.2em ;
        padding-top: 14px;
        padding-bottom: 30px;
    }
}

@media only screen and (min-width: 500px) {

    .cardSection{
        min-width: 20%;
    }
}

@media only screen and (min-width: 768px) {
    .cardTextDiv{
        padding: 1em 1em;
        gap: 1em;
    }
}

@media only screen and (min-width: 1200px) {
    .cardTextDiv{
        padding: 0em 2em;
        gap: 0em;
        padding-top: 30px;
    }

    .cardTextDiv>p{
        font-size: 1.5em !important;
        padding-top: 0px;
        padding-bottom: 44px;
    }
}