.footerSection{
    padding: 56px 0em;
}

.footerMenuDiv>p{
    color: var(--header-dark);
    font-weight: 700;
}

.footerMenuDiv>div{
    gap: 0.5em;
}

.footerBottomSection{
    margin-top: 100px;
}

.footerContentDiv>div {
    height: fit-content;
}

.logoImg{
    width: 130px;
    height: 130px;
}

@media only screen and (min-width: 100px){
    .footerContentDiv{
        width: 96%;
    }

    .footerMenuDiv>div>a{
        font-size: 1em;
        font-weight: 400;
    }

    /* .footerMenuDiv>div>a:hover{
        color: grey;
    } */

    .footerBottomSection{
        flex-direction: column !important;
        gap: 2em;
    }

    .footerBottomDiv{
        width: 98%;
        display: flex;
        align-items: center !important;
        justify-content: center;
    }

    .footerBottomDiv p{
        width: 100%;
        align-items: flex-start;
    }

    .footerContentDiv>div{
        flex-wrap: wrap ;
        justify-content: space-around;
        flex-direction: column;
    }

    .footerBottomSection{
        flex-direction: column;
    }

    .txtLogoImg{
        text-align: center;
    }

    .bottomFooterSocial{
        width: 90%;
    }

    .footerBottomSection .imageSection{
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media only screen and (min-width: 400px) {
    .bottomFooterSocial{
        width: 80%;
    }

    .footerBottomSection{
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;
    }

    .footerBottomSection .imageSection{
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media only screen and (min-width:500px){
    .footerContentDiv>div{
        flex-direction: row;
    }
}

@media only screen and (min-width: 970px){
    .footerContentDiv{
        width: 90%;
    }

    .bottomFooterSocial{
        width: 70%;
    }

    .footerContentDiv>.footerTopSection{
        /* flex-wrap: nowrap; */
        justify-content: space-between;
        align-items: flex-start;
    }

    .footerBottomSection{
        flex-direction: row;
        align-items: center;
        flex-wrap: nowrap;
    }

    .footerBottomDiv{
        width: auto;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .footerMenuDiv>div, .footerMenuDiv{
        align-items: flex-start;
    }

    .footerMenuDiv>div>a{
        font-size: 1.2em;
        font-weight: 400;
    }

    .footerDivider{
        display: none;
    }

    .txtLogoImg{
        text-align: left;
    }

    .imgLogoDiv{
        justify-content: flex-start;
    }
}

@media only screen and (min-width: 1050px){
    .footerBottomSection{
        flex-direction: row !important;
    }

    .footerContentDiv>div{
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: flex-end;
    }

    .footerBottomSection{
        flex-direction: row;
        align-items: flex-start;
        flex-wrap: nowrap;
    }

    .footerContentDiv>.footerTopSection{
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: flex-start;
    }

    .logoImg{
        min-width: 8em;
    }
}


@media only screen and (min-width: 1200px){
    .footerContentDiv{
        width: 80%;
    }

    .bottomFooterSocial{
        width: 70%;
    }

    .footerBottomDiv{
        width: fit-content;
    }

    .footerDivider{
        display: flex;
        width: 2px;

        min-height: 15em;
        background: black;
    }
}


@media only screen and (min-width: 1400px){
    .bottomFooterSocial{
        width: 50%;
    }
}