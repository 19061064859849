.contact-us-section{
    width: 100%;
    display: flex;
    justify-content: center;
    /* flex-direction: row-reverse; */
    gap: 1em;
    padding: 2em;
}

.contact-us-img-container{
    width: 30%;
    padding-top: 32%;
    position: relative;
    overflow: hidden;
}

.contact-us-abs-div{
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contact-us-abs-div>img{
    max-width: 100%;
}

.contact-us-txt-container{
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2em;
}

.contact-us-txt-div{
    display: flex;
    gap: 1em;
    align-items: center;
}

.contact-us-icon{
    display: flex;
    align-items: center;
    justify-content: center;
}

.contact-us-icon>svg{
    padding: 0.3em;
    font-size: 3em;
    color: white;
    background: black;
    border-radius: 50%;
}

.contact-us-txt{
    width: 100%;
    text-align: left;
}

@media only screen and (min-width: 100px) {
    .contact-us-section{
        flex-direction: column-reverse;
    }
    .contact-us-txt-container{
        width: 100%;
    }
    .contact-us-img-container{
        width: 100%;
    }
    .contact-us-map{
        /* width: 550px; */
        background: rgba(245, 245, 245, 1);
    }
}

@media only screen and (min-width: 768px) {
    
}

@media only screen and (min-width: 900px) {
    .contact-us-section{
        flex-direction: row;
    }
    .contact-us-txt-container{
        width: 40%;
    }

    .contact-us-img-container{
        width: 40%;
    }
    .contact-us-map{
        width: 550px;
    }
}

@media only screen and (min-width: 1200px) {
    .contact-us-txt-container{
        width: 25%;
    }

    .contact-us-img-container{
        width: 30%;
    }

    .contact-us-map{
        width: 600px;
    }
}