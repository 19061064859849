.productDetailSection{
    padding: 2em;
    overflow-y: hidden;
}

.productDetailDiv{
    width: 80%;
}

.productDetailImgContainer{
    width: 65%;
}

.productDetailImgContainer>div{
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.productDetailImgDiv{
    padding-top: 125%;
    position: relative;
    width: 100%;
    background: rgba(240, 240, 240, 1);
}

.productDetailImgAbsDiv{
    position: absolute;
    gap: 2.5em;
    inset: 0;
}

.productDetailImgAbsDiv img{
    max-width: 100%;
    max-height: 100%;
}

.productTextContentSection {
    width: 35%;
}

.productTextContentSection{
    padding: 0em 0em;
    justify-content: flex-start;
    align-items: flex-start;
}

.productTextDesc{
    font-size: 1.8em !important;
    font-weight: 400;
}

.productTextContentSection p{
    font-size: 1.2em;
    text-align: justify;
}

.productTextContentSection h4{
    font-weight: 500;
    font-size: 0.8em;
}

.productTextContentWishlistIcon{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: -1.5em;
}

.productWishlistIconBorder{
    padding: 0.5em;
    width: 2.5em;
    height: 2.5em;
    border: 1px solid var(--header-dark);
    cursor: pointer;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.productTextPriceDiv{
    padding-top: 2em; 
}

.productTextPriceDiv>h2{
    font-size: 2em;
    font-weight: 500;
}

.productSizeDiv{
    gap: 1.5em;
}

.productSizeDiv>p{
    font-size: 1em;
    font-weight: 500;
}

.productSize{
    flex-wrap: wrap;
}

.productSize .sizeTab{
    width: 5em;
    height: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--header-dark);
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    cursor: pointer;
}

.productSize .sizeTab.active{
    border: 1px solid var(--header-dark);
    background: var(--header-dark);
    color: white;
}
/* .productSize>a{
    width: 5em;
    height: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--header-dark);
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}

.productSize>a:hover{
    border: 1px solid var(--header-dark);
    background: var(--header-dark);
    color: white;
} */

.productTextContentSection>.buttonSection{
    width: 60%;
    margin-top: 2em;
}

.productTextContentSection>.buttonSection button{
    width: 100%;
    padding: 0.5em 1.5em;
    margin-bottom: 1em;
}

.dropdownBoxSection{
    gap: 0.5em;
}

.dropdownBoxSection> .dropdownButton{
    display: flex;
    justify-content: space-between;
    padding: 0.3em 0;
    border: none;
    background: transparent;
    cursor: pointer;
    font-size: 0.9em;
    font-weight: 500;
    font-family: jost200, 'san-serif';
}

.dropdownBoxText{
    overflow: hidden;
}

/* ===================================Size Modal=========================================== */
.sizeModal{
    width: 100%;
    padding: 1em;
}

.sizeModal>h2{
    font-weight: 450;
}
.sizeModal ul{
    width: fit-content;
    padding: 0.4em 0.5em;
    border-radius: 2em;
    list-style: none;
    gap: 0.5em;
    background: rgba(240, 240, 240, 1);
}

.sizeModal ul>li{
    
}

.sizeModal ul>li>a .active{
    padding: 0.2em 0.5em;
    background: white;
    border-radius: 2em;
}

.measurementDiv{
    display: grid;
    grid-template-columns: 1fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr;
    gap: 0.5em;
    align-items: end;
}

.measurementDiv hr{
    margin: 0;
}

.measurementBodyTypes>p{
    font-weight: 400;
}

/* ===================================Size Modal=========================================== */

@media only screen and (min-width: 100px){
    .productDetailSection>div{
        width: 100%;
    }
    .productDetailDiv{
        width: 100%;
    }

    .productDetailDiv{
        flex-direction: column;
    }

    .productDetailImgContainer{
        width: 100%;
    }
    
    .productTextContentSection {
        width: 100%;
    }

    .productDetailImgContainer>div{
        flex-direction: column;
    }

    .productDetailImgDiv{
        /* padding-top: 130%; */
    }

    .productTextContentSection>.buttonSection button{
        padding: 1em 1.5em;
        font-size: 0.9em;
    }

    .productTextContentSection>.buttonSection{
        width: 100%;
    }

    .productDetailImgContainer>div{
        display: grid;
        grid-template-columns: 1fr;

    }
}

@media only screen and (min-width: 768px){
    
    .productDetailDiv{
        width: 90%;
    }

    .productDetailImgContainer>div{
        flex-direction: row;
    }

    .productDetailImgDiv{
        /* padding-top: 130%; */
    }

    .productTextContentSection>.buttonSection button{
        padding: 0.5em 1.5em;
        font-size: 1em;
    }

    .productTextContentSection>.buttonSection{
        width: 60%;
    }

    .productDetailSection>div{
        width: 90%;
    }

    .productDetailImgContainer>div{
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
}

@media only screen and (min-width: 1200px){
    

    .productDetailSection{
        padding: 2em 0;
    }

    .productDetailDiv{
        flex-direction: row;
    }

    

    .productDetailImgContainer{
        width: 65%;
    }
    
    .productTextContentSection {
        width: 35%;
    }
}


@media only screen and (min-width: 1500px){
    .productDetailDiv{
        width: 80%;
    }

    .productDetailSection{
        padding: 2em;
    }

    .productDetailSection>div{
        width: 80%;
    }
}



/* ============================================You may also like Css================================================================ */

.ymalSection{
    padding: 3em 0em;
}

.ymalSection>h2{
    font-size: 2.5em;
    font-weight: 500;
    padding: 1em 0;
}

.ymalContent{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}


@media only screen and (min-width: 10px){
    .ymalContent{
        width: 100%;
        display: grid;
        grid-template-columns: 1fr;
    }

    .ymalContent>.productSection{
        max-width: 100%;
    }
}


@media only screen and (min-width: 768px){
    .ymalContent{
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    .ymalContent>.productSection{
        max-width: 100%;
    }
}


@media only screen and (min-width: 1200px){
    .ymalContent{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}


/* =======================================POPUP CSS============================================= */

.popupSection{
    width: 100%;
    /* max-height: 100vh !important; */
    position: fixed;
    z-index: 9999;
}

.popupBackground{
    position: absolute;
    inset: 0;
    background: rgba(0, 0, 0, 0.2);
}

/* .popupSection .slider{
    height: 100%;
} */

/* .popupImgSection{

} */

.popupImgMainContainer{
    max-height: 100%;
}


.popupImgSection{
    /* position: relative; */
    /* padding-top: 100%; */
    width: 100%;
    overflow: hidden;
    
}

.popupImgAbs{
    position: fixed;
    width: 100%;
    height: 100%;
    /* background: rgba(0, 0, 0, 0.2); */
}

.popupImgAbs>img{
    max-width: 100%;
    max-height: 100%;
}

.popupCloseBtn{
    position: absolute;
    top: 2em;
    right: 2em;
    cursor: pointer;
    z-index: 999;
}

.closeBtn{
    background: none;
    border: none;
    outline: none;
    
}


/* =======================================POPUP CSS============================================= */