
@font-face {
  font-family: jost400 ;
  src: url('https://fonts.googleapis.com/css2?family=Jost&display=swap');;
}

@font-face {
  font-family: heading;
  src: url('../../public/fonts/Noto_Serif_Display/NotoSerifDisplay-VariableFont_wdth\,wght.ttf');
}

@font-face {
  font-family: jost200;
  src: url('../../public/fonts/Jost/Jost-VariableFont_wght.ttf');;
}

:root {
  --max-width: 1100px;
  --border-radius: 12px;
  /* --font-mono: ui-monospace, Menlo, Monaco, 'Cascadia Mono', 'Segoe UI Mono',
    'Roboto Mono', 'Oxygen Mono', 'Ubuntu Monospace', 'Source Code Pro',
    'Fira Mono', 'Droid Sans Mono', 'Courier New', monospace; */

  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
  
  
  --header-dark: #323232;
  /* --txt-light: #e8d4d5; */
  --txt-light: white;
  /* --txt-light: rgb(253, 230, 217); */
  --sec-color: rgb(201, 150, 111);
  --bg-light: #fffaee;

  --primary-glow: conic-gradient(
    from 180deg at 50% 50%,
    #16abff33 0deg,
    #0885ff33 55deg,
    #54d6ff33 120deg,
    #0071ff33 160deg,
    transparent 360deg
  );
  --secondary-glow: radial-gradient(
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );

  --tile-start-rgb: 239, 245, 249;
  --tile-end-rgb: 228, 232, 233;
  --tile-border: conic-gradient(
    #00000080,
    #00000040,
    #00000030,
    #00000020,
    #00000010,
    #00000010,
    #00000080
  );

  --callout-rgb: 238, 240, 241;
  --callout-border-rgb: 172, 175, 176;
  --card-rgb: 180, 185, 188;
  --card-border-rgb: 131, 134, 135;
}

/* @media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;

    --primary-glow: radial-gradient(rgba(1, 65, 255, 0.4), rgba(1, 65, 255, 0));
    --secondary-glow: linear-gradient(
      to bottom right,
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0.3)
    );

    --tile-start-rgb: 2, 13, 46;
    --tile-end-rgb: 2, 5, 19;
    --tile-border: conic-gradient(
      #ffffff80,
      #ffffff40,
      #ffffff30,
      #ffffff20,
      #ffffff10,
      #ffffff10,
      #ffffff80
    );

    --callout-rgb: 20, 20, 20;
    --callout-border-rgb: 108, 108, 108;
    --card-rgb: 100, 100, 100;
    --card-border-rgb: 200, 200, 200;
  }
} */

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
  font-family: jost200, sans-serif !important;
  font-weight: 300;
}


@media only screen and (min-width:10px){
  .heading-txt{
    font-size: 1.5em;
  }

  .p-txt{
    font-size: 0.9em;
  }
  
}

@media only screen and (min-width:768px){
  .p-txt{
    font-family: jost200, sans-serif;
    font-weight: 300;
    letter-spacing: 0em;
    font-size: 1.9em;
  }
  .heading-txt{
    /* font-family: 'Didact Gothic', sans-serif; */
    letter-spacing: 0.1em;
    font-size: 2.5em;
    font-weight: 400;
  }
}



/* img{
  filter: saturate(1.3) hue-rotate(355deg);
  -webkit-filter: saturate(1.3) hue-rotate(355deg);
} */

body {
  color: rgb(var(--foreground-rgb));
  /* background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb)); */
}

hr{
  width: 100%;
  /* opacity: 1; */
  height: 1px;
  background: var(--header-dark);
  border: none;
  /* border-bottom: 1px solid var(--header-dark); */
}

img{
  max-width: 100%;
  max-height: 100%;
  width: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

.flex {
  display: flex;
}

.flex-centered{
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-between{
  display: flex;
  justify-content: space-between;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.gap-1{
  gap: 1em;
}

.gap-2{
  gap: 2em;
}

.gap-3{
  gap: 3em;
}

.gap-4{
  gap: 4em;
}

.gap-5{
  gap: 5em;
}

.w-100{
  width: 100%;
}

.w-80{
  width: 80%;
}

.w-70{
  width: 70%;
}

.w-60{
  width: 60%;
}

.w-50{
  width: 50%;
}

.w-40{
  width: 40%;
}

.w-30{
  width: 30%;
}

.w-20{
  width: 20%;
}

.w-10{
  width: 10%;
}

.productDetailTxtH3{
  font-weight: 450;
  font-size: 1.2em;
  letter-spacing: 0.15em;
}

@media only screen and (min-width: 10px){
  .productDetailTxtH3{
    font-size: 0.8em;
  }
}

@media only screen and (min-width: 768px){
  .productDetailTxtH3{
    font-size: 1.2em;
  }
}


