.modal-out{
    position: fixed;
    overflow-y: scroll;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0,0,0,.9);
    z-index: 10000;
    display: flex;
    justify-content: center;
    padding: 1em;
    align-items: center;
}
.modal-in{
    background-color: white;
    border-radius: 0.2em;
    padding: 18px;
    padding-top: 8px;
    min-height: 100px;
}

@media screen and (min-width: 768px) {
    .modal-in{
        width: 50%;
    }
}